import React, { useState, useEffect, useRef } from 'react';
import './imgstyle.css';
import { useNavigate } from 'react-router-dom';

const ConsoleText = ({ words, colors }) => {
  const [letterCount, setLetterCount] = useState(1);
  const [x, setX] = useState(1);
  const [waiting, setWaiting] = useState(false);
  const [visible, setVisible] = useState(true);
  const [currentWords, setCurrentWords] = useState(words);
  const [currentColors, setCurrentColors] = useState(colors);
  const textRef = useRef(null); // Ref to target the text element

  useEffect(() => {
    if (currentColors.length === 0) {
      setCurrentColors(['#fff']);
    }

    const intervalId = setInterval(() => {
      if (letterCount === 0 && !waiting) {
        setWaiting(true);
        setTimeout(() => {
          const usedColor = currentColors.shift();
          currentColors.push(usedColor);
          const usedWord = currentWords.shift();
          currentWords.push(usedWord);
          setX(1);
          if (textRef.current) {
            textRef.current.style.color = currentColors[0]; // Use the ref here
          }
          setLetterCount(1);
          setWaiting(false);
        }, 1000);
      } else if (letterCount === currentWords[0].length + 1 && !waiting) {
        setWaiting(true);
        setTimeout(() => {
          setX(-1);
          setLetterCount(letterCount - 1);
          setWaiting(false);
        }, 1000);
      } else if (!waiting) {
        if (textRef.current) {
          textRef.current.innerHTML = currentWords[0].substring(0, letterCount); // Use the ref here
        }
        setLetterCount(letterCount + x);
      }
    }, 120);

    return () => clearInterval(intervalId);
  }, [letterCount, waiting, currentWords, currentColors, x]);

  useEffect(() => {
    const underscoreIntervalId = setInterval(() => {
      setVisible((prevVisible) => !prevVisible);
    }, 400);

    return () => clearInterval(underscoreIntervalId);
  }, []);

  return (
    <div className="console-container">
      <span ref={textRef}></span> {/* Attach the ref here */}
      <div className="console-underscore" style={{ opacity: visible ? 1 : 0 }}>
        &#95;
      </div>
    </div>
  );
};

export default function Images() {
  const navigate = useNavigate();

  const don = () => {
    navigate('/Donate');
  };

  return (
    <div>
      <div className="container">
        <img
          src="https://media.gettyimages.com/id/524903696/photo/poor-indian-children-asking-for-food-india.jpg?s=612x612&w=0&k=20&c=uAUDyZRdpReAW51hD29W7fOCTDzNrOdHkAdKXErbapU="
          alt="Background"
          className="background-image"
        />
        <div className="content">
          <ConsoleText
            words={[
              'Delivering help and hope to children through sponsorship.',
              'Helping hands for those in need.',
              'Bringing smiles to faces.',
              'Together, we can change the world.',
            ]}
            colors={['white', 'white', 'white', 'white']}
          />
          <button
            style={{ width: '200px', height: '50px', display: 'block' }}
            className="donate-button donate"
            onClick={don}
          >
            Donate Now &#x1F496;
          </button>
        </div>
      </div>
    </div>
  );
}
